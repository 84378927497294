import React, { useEffect, useState } from 'react'
import './std-lib/Stylesheets/index.scss'
import { YMInitializer } from 'react-yandex-metrika'
import { createGlobalStyle, DefaultTheme, ThemeProvider } from 'styled-components'
import { StyleContext } from './std-lib/Сontext/StyleContext'
import * as amplitude from '@amplitude/analytics-browser'
import {
  flatObject,
  primaryThemeFragment,
  theme,
  ThemeFragment,
} from 'std-lib/Stylesheets/StyledVars/newVars'
import CoolErrorBoundary from './CoolErrorBoundary'
import { RouterProvider } from 'react-router'
import { router } from './router'
import { ENVIRONMENT } from './Constants'
import { useUserData } from './Widgets/Onboarding/UserDataStorage/UseUserData'

const GlobalStyles = createGlobalStyle<{
  style: DefaultTheme
  themeFragment: ThemeFragment
}>`

  body {
    background-color: var(--bg-color);
  }

  :root {
    --bg-color: ${({ themeFragment }) => themeFragment.bgColorPrimary};
    ${({ style }) =>
      Object.entries(flatObject(style)).map(item => `--${item[0]}: ${item[1]};`)};
  }
`

export const App = () => {
  const [themeFragment, setThemeFragment] = useState<ThemeFragment>(primaryThemeFragment)
  useUserData()
  useEffect(() => {
    console.log(ENVIRONMENT)
    if (window.location.host.includes('track.itmo.ru')) {
      amplitude.init('90029ac7a27916da4005a450c0b904f3')
    } else {
      amplitude.init('ab6dcbe4c464e420d786368d190a370b')
    }
  }, [])

  return (
    <>
      <YMInitializer
        accounts={[93390158]}
        version={'2'}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
      />

      <StyleContext.Provider
        value={{
          themeFragment: themeFragment,
          setThemeFragment: setThemeFragment,
        }}
      >
        <GlobalStyles style={theme} themeFragment={themeFragment} />
        <ThemeProvider theme={Object.assign(theme, themeFragment)}>
          <CoolErrorBoundary>
            <RouterProvider router={router} />
          </CoolErrorBoundary>
        </ThemeProvider>
      </StyleContext.Provider>
    </>
  )
}
