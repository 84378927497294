import React, { useState } from 'react'
import {
  BubbleMessageIcon,
  BubbleMessageIconWrapper,
  ContentWrapper,
  FormButtonWrapper,
  FormWrapper,
  InputsWrapper,
  InputWrapper,
  Links,
  SocialMediaWrapper,
  Wrapper,
} from './styled'
import Text from '../../../../std-lib/FastUI/Components/Text'
import { css } from 'styled-components'
import Input from '../../../../std-lib/UiKit/Input'
import Button from '../../../../std-lib/UiKit/Button'
import BubbleMessage from '../../../../Images/Static/bubbleMessage.svg'
import { sendTypedEvent } from '../../../../Utils/events/sendEvent'
import { finalRequest } from '../../API/finalRequest'
import {
  useValidationState,
  validationFuncs,
} from '../../../../std-lib/Hooks/useValidationState'
import { SocialMediaList } from '../../../../Components/SocialLink'

const ContactForm = () => {
  const [[email, setEmail], isEmailValid] = useValidationState<string>(
    '',
    validationFuncs.isEmail,
    true,
  )
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')
  const [isContactSaved, setIsContactSaved] = useState<boolean | undefined>(undefined)
  const sendPersonalData = async () => {
    try {
      await finalRequest.sendPersonalContacts(email)
      setStatus('Записали! Совсем скоро свяжемся с тобой')
      setIsContactSaved(true)
    } catch (error) {
      setStatus('Не получилось записать! Попробуй позже')
      setIsContactSaved(false)
    } finally {
      setTimeout(() => {
        setStatus('')
        setIsContactSaved(undefined)
      }, 4000)
    }
  }
  const handleBlur = () => {
    setIsFocused(false)
  }
  const handleFocus = () => {
    setIsFocused(true)
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <FormWrapper>
          <Text
            size={20}
            weight={600}
            fragment={css`
              margin-bottom: 24px;
            `}
            mobile={{
              size: 16,
              fragment: css`
                margin-bottom: 12px;
              `,
            }}
          >
            Проконсультируем по направлениям!
          </Text>
          <Text
            size={16}
            weight={400}
            fragment={css`
              margin-bottom: 48px;
            `}
            mobile={{
              size: 14,
              fragment: css`
                margin-bottom: 24px;
              `,
            }}
          >
            Если одного теста тебе маловато, или ты не нашел ответов на свои вопросы,
            оставь свой контакт — бесплатно проконсультируем по направлениям образования в
            ИТМО
          </Text>
          <InputsWrapper>
            <InputWrapper>
              <Text
                size={20}
                weight={600}
                mobile={{
                  size: 16,
                }}
              >
                Email
              </Text>
              <Input
                isValid={isEmailValid}
                placeholder={'example@mail.ru'}
                value={email}
                width={'100%'}
                onChange={e => setEmail(e.target.value)}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
              {!isEmailValid && !isFocused && (
                <Text weight={600} size={16} color={'#F57B7B'}>
                  Проверьте правильность формата (example@mail.ru)
                </Text>
              )}
            </InputWrapper>
          </InputsWrapper>
          <FormButtonWrapper>
            <Button
              isDisabled={!isEmailValid || !email}
              isValid={isContactSaved}
              buttonStyle={'secondary'}
              styledFragment={css`
                width: max-content;
                height: 38px;
                font-size: 16px;
                font-weight: 600;

                @media screen and (max-width: 1000px) {
                  width: 100%;
                }
              `}
              onClick={() => {
                sendTypedEvent('click', 'share_contact', 'trajectory')
                sendPersonalData()
              }}
            >
              {status ? status : 'Оставить контакт'}
            </Button>
          </FormButtonWrapper>
        </FormWrapper>
        <SocialMediaWrapper>
          <Links>
            <Text size={20} weight={600} mobile={{ size: 16 }}>
              Университет ИТМО в социальных сетях
            </Text>
            <Text size={16} weight={400} mobile={{ size: 14 }}>
              Подписывайся на наши соцсети, чтобы быть в курсе событий в жизни ИТМО!
            </Text>
            <SocialMediaList />
          </Links>
          <BubbleMessageIconWrapper>
            <BubbleMessageIcon src={BubbleMessage} />
          </BubbleMessageIconWrapper>
        </SocialMediaWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default ContactForm
