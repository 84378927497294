import styled from 'styled-components'

export const DefaultLayoutContainer = styled.div`
  background-color: var(--bg-color);
  transition: var(--bg-transition);
`

export const Content = styled.div`
  z-index: 300;
  max-width: 1920px;
  margin: auto;
  padding: 24px 60px;
  background-color: var(--bg-color);
  transition: var(--bg-transition);

  @media screen and (max-width: 1000px) {
    padding: 0 16px;
  }

  @media screen and (max-width: 433px) {
    padding: 0 15px !important;
  }
`
