import React from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'
import Start from './Widgets/Start'
import ErrorPage from './Widgets/ErrorPage'
import * as Sentry from '@sentry/react'
import Layout from 'LayoutComponents/Layout'
import Step from './Widgets/Onboarding/Layouts/Step'
import Profile from './Widgets/Onboarding/Slides/Profile'
import Final from './Widgets/Final'
import DeadEnd from './Widgets/DeadEnd'
import ModalContextProvider from './LayoutComponents/ModalContextProvider'
import EducationStage from 'Widgets/Onboarding/Slides/EducationStage'

export enum RoutesName {
  START = '/',
  EDUCATION_STAGE = '/stage',
  ONBOARDING = 'step/:section/:stepNumber',
  DEADLOCK = 'step/deadlock/:type',
  PROFILE = 'step/profile',
  TRAJECTORY = '/trajectory',
  NOT_FOUND = '/*',
}

export const routerConfig: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutesName.START,
        element: <Start />,
      },
      {
        path: RoutesName.EDUCATION_STAGE,
        element: <EducationStage />,
      },
      {
        path: RoutesName.ONBOARDING,
        element: <Step />,
      },
      {
        path: RoutesName.DEADLOCK,
        element: <DeadEnd />,
      },
      {
        path: RoutesName.PROFILE,
        element: <Profile />,
      },
      {
        path: RoutesName.NOT_FOUND,
        element: <ErrorPage error={new Error('Not Found Page')} />,
      },
    ],
  },
  {
    path: RoutesName.TRAJECTORY,
    element: (
      <ModalContextProvider>
        <Final />
      </ModalContextProvider>
    ),
    errorElement: <ErrorPage />,
  },
]

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter(routerConfig)
